<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form @submit.prevent="submitForm()">
      <div class="mb-4 w-2/3">
        <InputComponent :required="true" title="Title" v-model="post.title" />
      </div>
      <QuillEditor v-model:content="post.content" contentType="html" class="h-40 md:!h-60" theme="snow"
        :toolbar="toolbarOptions" />
      <div class="flex items-center justify-between mt-6">
        <div>
          <ButtonComponent v-if="data.post && hasCreatorAccess" :ordinary="true" type="error" @click="deletePost()">
            Delete
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4 ml-auto" v-if="!data.post">
          <ButtonComponent>Save Post</ButtonComponent>
          <ButtonComponent @click="submitForm(true)" :ordinary="true" type="success" v-if="hasCreatorAccess">
            {{ text.button }}
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4" v-else>
          <ButtonComponent @click="togglePublish()" :ordinary="true" type="success"
            v-if="hasCreatorAccess && !post.isPublished">
            Publish
          </ButtonComponent>
          <ButtonComponent @click="togglePublish()" :ordinary="true" type="dark"
            v-if="hasCreatorAccess && post.isPublished">
            Unpublish
          </ButtonComponent>
          <ButtonComponent>{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "PostModal",
};
</script>

<script setup>
import { computed, inject, ref } from "@vue/runtime-core";
import Post from "@/models/post";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { CreatePostDTO, UpdatePostDTO } from "@/models/dtos/post-dto";
import { useStore } from "vuex";
import notificationService from "@/services/notification.service";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Post,
    required: false,
  },
});

const store = useStore();
const openModal = inject("openModal");

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const propData = computed(() => {
  return props.data.post
    ? new UpdatePostDTO(props.data.post)
    : CreatePostDTO.prototype.createEmpty(props.data.page.id);
});
const user = computed(() => {
  return store.state.auth.user;
});

const post = ref(propData.value);

const publishPost = async (postID) => {
  const payload = { id: postID, isPublished: true }
  await store.dispatch("posts/update", payload);
};

const hasAdminAccess = computed(() => {
  return store.state.adminAccessRoles.includes(store.state.auth.user?.role);
});

const hasCreatorAccess = computed(() => {
  return store.state.creatorAccessRoles.includes(store.state.auth.user?.role);
});

const submitForm = async (publish) => {
  if (!post.value.content) {
    return notificationService.warning(
      "Please provide content for the post."
    );
  }

  if (!props.data.post) {
    const response = await store.dispatch("posts/create", post.value);
    if (publish) {
      await publishPost(response.data._id);
    }
    notificationService.success(response.message);
  } else {
    const message = await store.dispatch("posts/update", post.value);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return props.data.post
    ? { title: "Update post details", button: "Update" }
    : { title: "Create post", button: "Publish Post" };
});

const togglePublish = async () => {
  const payload = { id: post.value.id, isPublished: !post.value.isPublished }
  const message = await store.dispatch("posts/update", payload);
  notificationService.success(message);
  await emit("closeWithFunction");
};

const deletePost = () => {
  const closeFunction = store.state.modal.closeFunction;
  openModal(
    "delete",
    { type: "post", id: post.value.id },
    "medium",
    closeFunction
  );
};

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ 'header': 1 }, { 'header': 2 }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ 'size': ['small', false, 'large', 'huge'] }],
  [{ 'direction': 'rtl' }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'formula'],
  ['clean']
]

// eslint-disable-next-line
defineExpose({
  text,
  user,
  post,
  toolbarOptions,
  hasAdminAccess,
  hasCreatorAccess,
});
</script>
