<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form @submit.prevent="submitForm()" class="space-y-6">
      <div class="grid md:grid-cols-3 gap-6">
        <div class="md:col-span-1 rounded-lg overflow-hidden relative">
          <div
            class="
              cursor-pointer
              absolute
              z-20
              flex flex-col
              items-center
              justify-center
              w-full
              h-full
              bg-gray-300 bg-opacity-30
            "
            @click="openFileUploader('release-image')"
          >
            <IconComponent icon="charm:upload" />
            <p class="small bold select-none">Upload thumbnail</p>
          </div>
          <input
            @change="uploadImage($event)"
            type="file"
            class="hidden"
            name="release-image"
            id="release-image"
          />
          <img
            class="w-full h-full object-cover object-center"
            v-if="displayImage"
            :src="displayImage"
          />
        </div>
        <div class="form md:col-span-2">
          <InputComponent
            :required="true"
            title="Title"
            v-model="release.title"
          />
          <InputComponent
            type="date"
            :required="true"
            title="Publish Date"
            v-model="publishDate"
          />
        </div>
      </div>
      <div
        v-if="isContentEmpty"
        class="
          border-dashed border-2 border-gray-300
          p-6
          space-y-4
          cursor-pointer
          flex flex-col
          items-center
          justify-center
          w-full
          h-full
          bg-gray-300 bg-opacity-30
        "
        @click="openFileUploader('release-document')"
      >
        <IconComponent class="text-3xl" icon="charm:upload" />
        <div class="flex items-center">
          <p v-if="!release.document" class="small bold select-none">
            Upload document
          </p>
          <p
            v-if="release.document"
            class="small bold select-none"
            :class="!uploadedDocument ? '' : 'text-system-green'"
          >
            {{ !uploadedDocument ? "Replace Document" : "Document Uploaded!" }}
          </p>
        </div>
        <input
          @change="uploadDocument($event)"
          type="file"
          accept=".pdf"
          class="hidden"
          name="release-document"
          id="release-document"
        />
      </div>
      <span
        class="text-xs float-right !mt-1 !mb-2 cursor-pointer"
        v-if="displayDocument"
        @click="removeDocument()"
        >Remove Document</span
      >
      <div v-if="!displayDocument">
        <QuillEditor
          v-model:content="release.content"
          contentType="html"
          class="h-40 md:!h-60"
          theme="snow"
          toolbar="full"
        />
      </div>
      <div v-else>
        <div class="w-full h-40 md:!h-60 overflow-auto">
          <vue-pdf-embed :source="displayDocument" />
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div>
          <ButtonComponent
            v-if="!isNew && hasAdminAccess"
            :ordinary="true"
            type="error"
            @click="deleteRelease()"
          >
            Delete
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4 ml-auto" v-if="isNew">
          <ButtonComponent>Save Release</ButtonComponent>
          <ButtonComponent
            @click="submitForm(true)"
            :ordinary="true"
            type="success"
            v-if="hasPublishAccess"
          >
            {{ text.button }}
          </ButtonComponent>
        </div>
        <div class="flex items-center space-x-4" v-else>
          <ButtonComponent
            @click="togglePublish()"
            :ordinary="true"
            type="success"
            v-if="hasPublishAccess && !release.isPublished"
          >
            Publish
          </ButtonComponent>
          <ButtonComponent
            @click="togglePublish()"
            :ordinary="true"
            type="dark"
            v-if="hasPublishAccess && release.isPublished"
          >
            Unpublish
          </ButtonComponent>
          <ButtonComponent>{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ReleaseModal",
};
</script>

<script setup>
import { computed, inject, ref } from "@vue/runtime-core";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { CreateReleaseDTO, UpdateReleaseDTO } from "@/models/dtos/release-dto";
import { useStore } from "vuex";
import notificationService from "@/services/notification.service";

// eslint-disable-next-line
const props = defineProps({
  data: {
    required: false,
  },
});

const store = useStore();
const openModal = inject("openModal");

// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);
const today = new Date().toLocaleDateString("sv-SE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const isNew = computed(() => {
  return typeof props.data === "string";
});

const propData = computed(() => {
  return !isNew.value
    ? new UpdateReleaseDTO(props.data)
    : CreateReleaseDTO.prototype.createEmpty();
});
const user = computed(() => {
  return store.state.auth.user;
});

const release = ref(propData.value);
const publishDate = ref(release.value.publishDate || today);

const displayImage = ref(release.value.image);
const displayDocument = ref(release.value.document);
const uploadedImage = ref();
const uploadedDocument = ref();

const isContentEmpty = computed(() => {
  return release.value.content === "<p><br></p>" || release.value.content === ""
    ? true
    : false;
});

const publishRelease = async (releaseID) => {
  const formData = new FormData();
  formData.append("id", releaseID);
  formData.append("isPublished", true);
  await store.dispatch("releases/update", formData);
};

const hasAdminAccess = computed(() => {
  return store.state.adminAccessRoles.includes(store.state.auth.user?.role);
});

const hasPublishAccess = computed(() => {
  return store.state.publishAccessRoles.includes(store.state.auth.user?.role);
});

const removeDocument = async () => {
  uploadedDocument.value = "";
  displayDocument.value = "";
  release.value.document = "";
};

const submitForm = async (publish) => {
  if (!release.value.content && !uploadedDocument.value) {
    return notificationService.warning(
      "Please provide content for the release."
    );
  }

  const formData = new FormData();
  uploadedImage.value ? formData.append("image", uploadedImage.value) : null;
  uploadedDocument.value
    ? formData.append("document", uploadedDocument.value)
    : null;
  release.value.publishDate = `${publishDate.value}T00:00:00`;
  release.value.magazine = isNew.value ? props.data : props.data.magazine;
  delete release.value.image;
  delete release.value.document;

  for (const data in release.value) {
    formData.append(data, release.value[data]);
  }

  if (isNew.value) {
    if (!uploadedImage.value) {
      return notificationService.warning(
        "Please upload an image to create this release"
      );
    }
    const response = await store.dispatch("releases/create", formData);
    if (publish) {
      await publishRelease(response.data._id);
    }
    notificationService.success(response.message);
  } else {
    const message = await store.dispatch("releases/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return !isNew.value
    ? { title: "Update release details", button: "Update" }
    : { title: "Create a new Release", button: "Publish Release" };
});

const togglePublish = async () => {
  const formData = new FormData();
  formData.append("id", release.value.id);
  formData.append("isPublished", !release.value.isPublished);
  const message = await store.dispatch("releases/update", formData);
  notificationService.success(message);
  await emit("closeWithFunction");
};

const openImageUploader = () => {
  const image = document.getElementById("release-image");
  image.click();
};

const openFileUploader = (elementId) => {
  const file = document.getElementById(elementId);
  file.click();
};

const uploadImage = ($event) => {
  const image = $event.target.files[0];
  displayImage.value = URL.createObjectURL(image);
  uploadedImage.value = image;
};

const uploadDocument = ($event) => {
  const documentURL = $event.target.files[0];
  displayDocument.value = URL.createObjectURL(documentURL);
  uploadedDocument.value = documentURL;
};

const deleteRelease = () => {
  const closeFunction = store.state.modal.closeFunction;
  openModal(
    "delete",
    { type: "release", id: release.value.id },
    "medium",
    closeFunction
  );
};

// eslint-disable-next-line
defineExpose({
  text,
  user,
  today,
  isNew,
  release,
  hasAdminAccess,
  hasPublishAccess,
  publishDate,
  displayImage,
  displayDocument,
  isContentEmpty,
  openImageUploader,
  openFileUploader,
  uploadImage,
  uploadDocument,
});
</script>
